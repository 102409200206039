export const environment = {
  production: false,
  // https://bbwapi-dev.mplatformtech.com/
  baseApiUrl: 'https://uat-api.bbwuae.com/v2/',
  backendDomain: 'https://uat-api.bbwuae.com/',
  googleKey: 'AIzaSyBBoylO2TyrZGngh00NHXCG6pwr46f4L7Q',
  googleMapUrl:
    'https://maps.googleapis.com/maps/api/js?v=weekly&key=${key}&libraries=visualization,places,drawing,marker,geometry&v=weekly&callback=Function.prototype',
};

// need to update google key & google map url 