import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';

import { GeneralService } from '../../../core/services/general.service';
import { StorageService } from '../../../core/services/storage.service';
import { VendorService } from '../../../core/services/vendor.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  @ViewChild('notificationButton') notificationButton!: ElementRef;
  @ViewChild('notificationDiv') notificationDiv!: ElementRef;

  isNotificationModalOpen = false;
  orderNotificationsLists: any[] = [];
  invoiceNotifications: any[] = [];
  invoiceNotificationsTodos: any[] = [];
  isNewNotificationGet = false;
  currentTab = 'all';
  vendorID = this.storageService.getCookie('userID');
  totalInvCount: any = 0;
  totalNotiCount: any = 0;

  constructor(
    private vendorService: VendorService,
    private storageService: StorageService,
    private renderer: Renderer2,
    private generalService: GeneralService
  ) {}

  ngOnInit(): void {
    this.socketInit();
    this.getNewNotification();

    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        e.target !== this.notificationButton?.nativeElement &&
        e.target !== this.notificationDiv?.nativeElement &&
        !this.notificationDiv.nativeElement.contains(e.target) &&
        !this.notificationButton.nativeElement.contains(e.target)
      ) {
        this.isNotificationModalOpen = false;
        document
          .getElementsByTagName('nav')[0]
          ?.classList?.remove('body-layer');
      }
    });
  }

  socketInit(): void {
    if (this.vendorID) {
      this.vendorService.socketConnection(this.vendorID);
    }
  }

  getInvoiceNotifications(): void {
    return;
    this.vendorService.getInvoiceNotifications(this.vendorID).subscribe({
      next: (res) => {
        this.invoiceNotifications = res?.data?.done;
        this.invoiceNotificationsTodos = res?.data?.todo;
        this.totalInvCount = res?.total_records;
      },
      error: (err) => {
        this.generalService.displayError(err.error.message);
      },
    });
  }

  getNotifications(isRequest = false): void {
    const payload = {
      isAll: false,
      isRequest: isRequest,
    };
    this.vendorService
      .getHeaderNotifications(
        this.vendorID,
        this.generalService.createParamsURL(payload)
      )
      .subscribe({
        next: (res) => {
          this.orderNotificationsLists = res.data;
          if (payload.isRequest) {
            this.currentTab = 'request';
            this.totalInvCount = res?.all_count;
          } else {
            this.totalNotiCount = res?.all_count;
            this.currentTab = 'all';
          }
        },
        error: (err) => {
          this.generalService.displayError(err.error.message);
        },
      });
  }

  getNewNotification(): void {
    // this.getInvoiceNotifications();
    this.getNotifications(true);
    this.getNotifications();
    // this.vendorService
    //   .listenEvent('vendorInvoiceNotification')
    //   .subscribe((data: any) => {
    //     if (data && data?._id) {
    //       this.getInvoiceNotifications();
    //       this.isNewNotificationGet = this.isNotificationModalOpen
    //         ? false
    //         : true;
    //     }
    //   });
    this.vendorService
      .listenEvent('adminNotificationData')
      .subscribe((data: any) => {
        if (data && data?._id) {
          this.getNotifications();
          this.isNewNotificationGet = this.isNotificationModalOpen
            ? false
            : true;
        }
      });
  }

  openModal(): void {
    this.isNewNotificationGet = false;
    this.isNotificationModalOpen = !this.isNotificationModalOpen;
    if (this.isNotificationModalOpen) {
      document.getElementsByTagName('nav')[0]?.classList?.add('body-layer');
    } else {
      document.getElementsByTagName('nav')[0]?.classList?.remove('body-layer');
    }
  }

  sendInvoice(): void {
    this.isNotificationModalOpen = false;
    document.getElementsByTagName('nav')[0]?.classList?.remove('body-layer');
  }

  ngOnDestroy(): void {
    this.vendorService.socketDisconnect();
    this.isNewNotificationGet = false;
  }

  closeNotifyModal(): void {
    this.isNotificationModalOpen = false;
    document.getElementsByTagName('nav')[0]?.classList?.remove('body-layer');
  }

  markAsReadNotification(item: any, isall: boolean): void {
    if (item.is_read) {
      return;
    }

    const payload = {
      notification_id: item._id,
      is_all: false,
    };
    this.vendorService.markAsReadNotification(payload).subscribe({
      next: (res) => {
        // this.getInvoiceNotifications();

        this.getNotifications(isall);
      },
      error: (err) => {
        this.generalService.displayError(err.error.message);
      },
    });
  }
}
